import { Component, OnInit, OnDestroy } from '@angular/core'
import { MobileNavState } from '../services/http.service'
import { ActivatedRoute } from '@angular/router'
import { SubscriptionTracker } from '../subscription-tracker/subscription-tracker'
import { CookieService } from 'ngx-cookie-service'
import { Select, Store } from '@ngxs/store'
import { InfoState } from '../states/info-state'
import { Observable } from 'rxjs'
import { environment } from 'environments/environment'
import { GetInfo } from 'app/models/get-info'
import { Transaction_Pool } from 'app/models/transaction_pool'
import { TransactionPoolState } from 'app/states/transaction-pool-state'
import { TransactionPoolInfos } from 'app/actions/get-transaction-pool-info.actions'
import { BlockDetailsState } from 'app/states/block-details-state'
import { BlockDetail } from 'app/models/block_detail'
import { BlockDetails } from 'app/actions/get-block-details.actions'
import { trigger, state, style, animate, transition, keyframes, stagger, query } from '@angular/animations'

@Component({
    selector: 'app-blockchain',
    templateUrl: './blockchain.component.html',
    styleUrls: ['./blockchain.component.scss'],
    providers: [],
    animations: [
        trigger('highlightNewItems', [
            state('new', style({
                backgroundColor: 'transparent'
            })),
            state('old', style({
                backgroundColor: 'transparent'
            })),            
            transition('void => new', [
                style({ backgroundColor: '#1CC129' }),
                animate('2000ms ease-out', style({ backgroundColor: 'transparent' }))
            ]),
            transition('* => new', [
                style({ backgroundColor: '#1CC129' }),
                animate('2000ms ease-out', style({ backgroundColor: 'transparent' }))
            ])
        ]),
        trigger('blockAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(-50px)', opacity: 0 }),
                animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
            ])
        ]),
        trigger('chainSlide', [
            transition('* => *', [
                query(':enter', [
                    style({ transform: 'translateX(-50px)', opacity: 0 })
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateX(0)', opacity: 1 }),
                    animate('300ms ease-out', style({ transform: 'translateX(50px)', opacity: 0 }))
                ], { optional: true }),
                query(':enter', [
                    animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 }))
                ], { optional: true })
            ])
        ])
    ]
})
export class BlockchainComponent
    extends SubscriptionTracker
    implements OnInit, OnDestroy
{
    info: GetInfo
    daemon_network_state: any
    setLimit: any
    limit: any
    currentPage: number
    goToBlock: number
    setBlock: number
    maxViewedBlockHeight: number
    maxViewedBlockHeights: Array<number> = []
    recentBlocks: BlockDetail[] = [];
    maxVisibleBlocks = 15;
    enableVisibilityInfo = environment.enableVisibilityInfo

    maxViewedPoolTimestamp: number
    maxViewedPoolTimestamps: Array<number> = []

    poolsOn: boolean
    poolLimit: number
    setBlockValid: boolean
    listBlockStart: number
    maxCountBlock: number
    txCount: number
    setPoolLimit: number = environment.transactionPoolLimit
    lastSendBlockDetail = {
        start: 0,
        limit: 0
    }
    loader: boolean
    navIsOpen: boolean
    searchIsOpen: boolean = false
    transactionCount: number = 0
    blockCount: number = 0
    totalPosCoin: number = 0
    premine: number = 2178309
    circulatesCoin: number = 0

    @Select(InfoState.selectDaemonInfo) getInfo$: Observable<GetInfo[]>
    @Select(TransactionPoolState.selectLimitedTransactionPoolInfo) getLimitedTransactionPoolInfo$: Observable<Transaction_Pool[]>
    @Select(BlockDetailsState.selectRangeOfBlockDetails) selectRangeOfBlockDetails$: Observable<BlockDetail[]>

    onIsVisible($event): void {
        this.searchIsOpen = $event
    }

    constructor(
        private route: ActivatedRoute,
        private cookieService: CookieService,
        private mobileNavState: MobileNavState,
        private store: Store
    ) {
        super()
        this.daemon_network_state = {
            0: 'Offline',
            1: 'Synchronizing',
            2: 'Online',
            3: 'Loading core',
            4: 'System error',
            5: 'Completing work'
        }
        this.maxCountBlock = 1000
        this.poolsOn = true
        this.setBlockValid = true
        this.setLimit = 20
        this.loader = false
        this.navIsOpen = false
    }

    getInfoPrepare(data) {
        this.info = data;
        this.calcCoin();
        this.onChange();
    }

    ngOnInit() {
        if (this.cookieService.get('OnOffButtonCookie')) {
            if (this.cookieService.get('OnOffButtonCookie') === 'true') {
                this.poolsOn = true
            } else if (
                this.cookieService.get('OnOffButtonCookie') === 'false'
            ) {
                this.poolsOn = false
            }
        } else {
            this.poolsOn = true
        }
        if (this.cookieService.get('setLimitCookie')) {
            this.setLimit = parseInt(
                this.cookieService.get('setLimitCookie'),
                10
            )
        }
        this.currentPage = 1
        this.setBlock = null

        this.getInfoPrepare(this.route.snapshot.data['MainInfo'])
        
        
        this._track(
            this.getInfo$.subscribe((data) => {
                this.getInfoPrepare(data[0])
            }),
            this.getLimitedTransactionPoolInfo$.subscribe(transactions => {
                if (this.maxViewedPoolTimestamps.length === 0) {
                    this.maxViewedPoolTimestamps = [0]
                }
                this.maxViewedPoolTimestamp = Math.max(...this.maxViewedPoolTimestamps)
                this.maxViewedPoolTimestamps = transactions.map(transaction => transaction.timestamp)
                this.transactionCount = transactions.length
            }),
            // Объединенная подписка на блоки
            this.selectRangeOfBlockDetails$.subscribe(blocks => {
                if (this.maxViewedBlockHeights.length === 0) {
                    this.maxViewedBlockHeights = [this.info?.height - 1]
                }
                this.maxViewedBlockHeight = Math.max(...this.maxViewedBlockHeights)
                this.maxViewedBlockHeights = blocks.map(block => block.height)
                this.blockCount = blocks.length
                this.loader = false
                
                // Обновление визуализации блокчейна
                if (blocks.length > 0) {
                    this.updateRecentBlocks(blocks);
                }
            }),
            this.mobileNavState.change.subscribe((navIsOpen) => {
                this.navIsOpen = navIsOpen
            })
        )
        this.calcCoin()
        this.onChange()
    }
    
    private updateRecentBlocks(blocks: BlockDetail[]) {
        const sortedBlocks = [...blocks].sort((a, b) => b.height - a.height);
        const currentHeights = new Set(this.recentBlocks.map(block => block.height));
        
        // Проверяем, есть ли новые блоки
        const hasNewBlocks = sortedBlocks.some(block => !currentHeights.has(block.height));
        
        if (hasNewBlocks) {
            // Обновляем блоки только если есть новые
            this.recentBlocks = sortedBlocks.slice(0, this.maxVisibleBlocks).map(block => ({
                ...block,
                isNew: !currentHeights.has(block.height)
            }));
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    onChangePoolLimit() {
        this.store.dispatch(new TransactionPoolInfos.SetLimit(this.setPoolLimit))
    }

    toggleBtn() {
        this.poolsOn = !this.poolsOn
        const exp = new Date()
        exp.setMonth(exp.getMonth() + 3)
        this.cookieService.set('OnOffButtonCookie', String(this.poolsOn), {
            expires: exp
        })
    }

    calcCoin() {
        this.circulatesCoin = Math.floor(Number(this.info.total_coins) / 1000000000000 - this.premine);
        let fivePercent = this.circulatesCoin * 0.05;
    
        if ((this.circulatesCoin - fivePercent) < (Math.floor(Number(this.info.pos_difficulty) / 1000000000000 / 288))) {
            this.totalPosCoin = Math.floor((Number(this.info.pos_difficulty) / 1000000000000 / 288) - fivePercent);
        } else {
            this.totalPosCoin = Math.floor(Number(this.info.pos_difficulty) / 1000000000000 / 288);
        }
    }

    prevPage() {
        if (this.currentPage - 1 > 0) {
            this.currentPage--
            this.onChange()
        }
    }

    nextPage() {
        if (this.currentPage !== Math.ceil(this.info.lastBlock / this.limit)) {
            this.currentPage++
            this.onChange()
        } else {
            return false
        }
    }

    onChangeLimit() {
        if (
            isNaN(+this.goToBlock) === false &&
            this.goToBlock !== undefined &&
            +this.goToBlock >= 0 &&
            +this.goToBlock < this.info.lastBlock
        ) {
            this.listBlockStart =
                +this.goToBlock -
                +this.setLimit +
                1 +
                ((this.info.lastBlock - 1 - +this.goToBlock) % +this.setLimit)
            this.currentPage =
                Math.floor(
                    (this.info.lastBlock - +this.setLimit - (this.listBlockStart + 1)) /
                        +this.setLimit
                ) + 2
        }
        this.onChange()
    }

    searchBlock() {
        this.goToBlock = this.setBlock
        if (
            isNaN(+this.goToBlock) ||
            +this.goToBlock < 0 ||
            +this.goToBlock >= this.info.lastBlock
        ) {
            this.setBlockValid = false
            return
        }
        this.setBlockValid = true
        this.listBlockStart =
            +this.goToBlock -
            +this.setLimit +
            1 +
            ((this.info.lastBlock - 1 - +this.goToBlock) % +this.setLimit)
        this.currentPage =
            Math.floor(
                (this.info.lastBlock - +this.setLimit - (this.listBlockStart + 1)) /
                    +this.setLimit
            ) + 2
        this.maxViewedBlockHeights = [this.listBlockStart + 10]
        this.onChange()
    }

    onChange() {
        if (!!this.info) {
            if (this.setLimit > this.maxCountBlock) {
                this.setLimit = this.maxCountBlock
            }
            if (!this.setLimit || this.setLimit < 0) {
                this.setLimit = 20
            }
            this.listBlockStart =
            this.info.lastBlock +
                1 -
                +this.setLimit -
                (this.currentPage - 1) * +this.setLimit
            this.limit = +this.setLimit

            this.cookieService.set('setLimitCookie', this.limit)

            if (this.listBlockStart < 0 || this.listBlockStart === null) {
                this.limit = this.limit + this.listBlockStart
                if (this.limit < 0) {
                    return
                }
                this.listBlockStart = 0
            }
            if (
                this.lastSendBlockDetail.start !== this.listBlockStart ||
                this.lastSendBlockDetail.limit !== this.limit
            ) {
                this.lastSendBlockDetail.start = this.listBlockStart
                this.lastSendBlockDetail.limit = this.limit
                this.loader = true

                this.store.dispatch(new BlockDetails.SetRange(this.listBlockStart, this.limit))
            }
        }
    }
}
