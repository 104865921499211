<h1 class="hidden">Welcome to Block Explorer!</h1>
<header>
    <button
        type="button"
        id="mobile-nav-btn"
        (click)="btnToggleMenu()"
        [ngClass]="{ show: navIsOpen === true }"></button>
    <a class="logo" href="https://nirmata-network.com/">
        <div class="coin-wrapper">
            <div class="coin">
                <div class="coin-face front">
                    <img src="assets/img/NN_F.svg" alt="Nirmata Logo">
                </div>
                <div class="coin-face back">
                    <img src="assets/img/NN_F.svg" alt="Nirmata Logo">
                </div>
            </div>
        </div>
    </a>
    <nav class="desktop_nav">
        <ul>
            <li>
                <a
                    [routerLink]="['/']"
                    routerLinkActive="active"
                    id="blockchain-li"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Blockchain</a
                >
            </li>
            <li>
                <a [routerLink]="['alt-blocks']" routerLinkActive="active"
                    >Alt-blocks</a
                >
            </li>
            <li>
                <a [routerLink]="['aliases']" routerLinkActive="active"
                    >Aliases</a
                >
            </li>
            <li
                ><a [routerLink]="['charts']" routerLinkActive="active"
                    >Charts</a
                ></li
            >
            <li><a [routerLink]="['api']" routerLinkActive="active">API</a></li>
            <li>
                <div class="nav_search">
                    <app-search></app-search>
                </div>
            </li>
        </ul>
    </nav>
</header>

<main>
    <nav class="mobile_nav" [ngClass]="{ show: navIsOpen === true }">
        <ul>
            <li>
                <a
                    [routerLink]="['/']"
                    routerLinkActive="active"
                    id="blockchain-mobile-li"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Blockchain</a
                >
            </li>
            <li>
                <a [routerLink]="['alt-blocks']" routerLinkActive="active"
                    >Alt-blocks</a
                >
            </li>
            <li>
                <a [routerLink]="['aliases']" routerLinkActive="active"
                    >Aliases</a
                >
            </li>
            <li
                ><a [routerLink]="['charts']" routerLinkActive="active"
                    >Charts</a
                ></li
            >
            <li><a [routerLink]="['api']" routerLinkActive="active">API</a></li>
            <li>
                <div class="nav_search">
                    <app-search></app-search>
                </div>
            </li>
        </ul>
    </nav>
    <router-outlet></router-outlet>
</main>