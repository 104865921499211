import { Component, OnDestroy, OnInit } from '@angular/core'
import { SubscriptionTracker } from '../subscription-tracker/subscription-tracker'
import { Observable } from 'rxjs'
import { VisibilityInfo } from '../models/visibility-info'
import { VisibilityState } from 'app/states/visibility-state'
import { ActivatedRoute } from '@angular/router'
import { Store, Select } from '@ngxs/store'
import { InfoState } from 'app/states/info-state'

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent extends SubscriptionTracker
implements OnInit, OnDestroy {

    title: string = 'Profitability 24h'
    NetworkHashrate: number
    info: any
    earningsPOW: number;
    earningsPOS: number;
    Award:number = 720;
    amount: number = 0
    percentage: number = 0

    @Select(VisibilityState.selectVisibilityInfo) getVisibilityInfo$: Observable<VisibilityInfo[]>
    @Select(InfoState.selectDaemonInfo) getInfo$: Observable<Response[]>

    calculateEarningsPow(): void {
      const calc = ((this.Award / this.NetworkHashrate) * 100).toFixed(2);
      this.earningsPOW = Number(calc)
    }

    calculateEarningsPos(): void {
      const calc = ((this.Award / this.amount) * 10000).toFixed(2);
      this.earningsPOS = Number(calc)
    }

    constructor(private route: ActivatedRoute, private store: Store) {
      super()
  }

    getInfoPrepare(data) {
      this.info = data
      if (this.info) {
          this.NetworkHashrate = this.info.current_network_hashrate_350 / 1000000;
      }
  }

    ngOnInit(): void {
      this.getInfoPrepare(this.route.snapshot.data['MainInfo'])
        this._track(
            this.getInfo$.subscribe((data) => {
                this.getInfoPrepare(data[0])
            })
        )
      this.calculateEarningsPow()
      this._track(
        this.getVisibilityInfo$.subscribe((data) => {
            if (data.length === 1) {
                this.amount = data[0].amount / 1000000000000
                this.calculateEarningsPos()
            }
        })
      )  
      
    }

    ngOnDestroy(): void {
        super.ngOnDestroy()
    }
}
