<div class="overlay" *ngIf="loader === true">
    <div class="loader"></div>
</div>

<div class="top_box" *ngIf="navIsOpen === false">
    <div class="left_box">
        <div
            class="mobile_heading"
            [ngClass]="{ hide_heading: searchIsOpen === true }">
            Alt-Blocks
        </div>
    </div>
    <app-search (isVisible)="onIsVisible($event)"></app-search>
</div>

<div class="wrapper_table" *ngIf="altBlocks">
    <table class="table_alt_blocks">
        <thead *ngIf="altBlocks.length">
            <tr>
                <th class="height">Height</th>
                <th class="timestamp">Timestamp (UTC)</th>
                <th class="actual_timestamp">Actual timestamp (UTC)</th>
                <th class="size">Size</th>
                <th class="transactions">Transactions</th>
                <th class="hash">Hash</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of altBlocks">
                <td class="height">
                    <a [routerLink]="['/alt-blocks', item.hash]">{{
                        item.height
                    }}</a>
                    <span *ngIf="item.type == 0">(PoS)</span>
                    <span *ngIf="item.type == 1">(PoW)</span>
                </td>
                <td class="timestamp">
                    {{
                        item.timestamp * 1000 | date: 'YYYY-MM-dd HH:mm:ss':'+0000'
                    }}
                </td>
                <td class="actual_timestamp">
                    {{
                        item.actual_timestamp * 1000 | date: 'YYYY-MM-dd HH:mm:ss':'+0000'
                    }}
                </td>
                <td class="size">{{ item.block_cumulative_size }} bytes</td>
                <td class="transactions">{{
                    item.transactions_details.length
                }}</td>
                <td class="hash">
                    <span class="cont_hash">
                        <a [routerLink]="['/alt-blocks', item.hash]">{{
                            item.hash
                        }}</a>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="no_data" *ngIf="!altBlocks.length">No data</div>

    <div
        class="pagination"
        *ngIf="visiblePagination === true && altBlocks.length !== 0">
        <div class="left_position">
            <span>Pages:</span>
            <button
                type="button"
                class="prev_btn"
                [disabled]="currentPage <= 1"
                (click)="prevPage()">
                <svg x="0px" y="0px" viewBox="0 0 477.175 477.175">
                    <g>
                        <path
                            d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
              c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" />
                    </g>
                </svg>
            </button>
            <button
                type="button"
                class="next_btn"
                [disabled]="altBlocks.length < count"
                (click)="nextPage()">
                <svg x="0px" y="0px" viewBox="0 0 477.175 477.175">
                    <g>
                        <path
                            d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
              c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
              " />
                    </g>
                </svg>
            </button>
            <input
                type="text"
                [value]="currentPage"
                (input)="currentPage = $event.target.value"
                (change)="onChange()" />
        </div>
        <div class="right_position">
            <span>Items on page:</span>
            <input
                type="text"
                [value]="count"
                (input)="count = $event.target.value"
                (change)="onChange()" />
        </div>
    </div>
</div>
