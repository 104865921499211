<div class="info_charts">
    <h1>Information charts</h1>
    <div class="wrap_links_chart">
        <div *ngIf="loader === true" class="wrap_loader">
            <div class="loader"></div>
        </div>
        <a [routerLink]="['/charts/avg-block-size']" *ngIf="loader !== true">
            <span>Average Block Size</span>
            <div [chart]="previewAvgBlockSizeChart"></div>
        </a>
        <a [routerLink]="['/charts/avg-trans-per-block']" *ngIf="loader !== true">
            <span>Average Number Of Transactions Per Block</span>
            <div [chart]="previewAvgTransPerBlockChart"></div>
        </a>
        <a [routerLink]="['/charts/hash-rate']" *ngIf="loader !== true">
            <span>Hash Rate</span>
            <div [chart]="previewHashRateChart" *ngIf="loader !== true"></div>
        </a>
        <a [routerLink]="['/charts/difficulty-pow']" *ngIf="loader !== true">
            <span>PoW Difficulty</span>
            <div [chart]="previewDifficultyPoWChart" *ngIf="loader !== true"></div>
        </a>
        <a [routerLink]="['/charts/difficulty-pos']" *ngIf="loader !== true">
            <span>PoS Difficulty</span>
            <div [chart]="previewDifficultyPoSChart" *ngIf="loader !== true"></div>
        </a>
        <a [routerLink]="['/charts/confirm-trans-per-day']" *ngIf="loader !== true">
            <span>Confirmed Transaction Per Day</span>
            <div [chart]="previewConfirmTransactPerDayChart"></div>
        </a>
    </div>
</div>

<!--<router-outlet></router-outlet>-->
