<div class="main_info">
    <div class="height">
        <div class="title">Height</div>
        <div class="value">{{ height | bitNumber }}</div>
    </div>
    <div class="difficulty">
        <div class="title">Difficulty</div>
        <div class="value">
            <div class="pos"
                ><span>PoS:</span>{{ posDifficulty | bitNumber }}</div
            >
            <div class="pow"
                ><span>PoW:</span>{{ powDifficulty | bitNumber }}</div
            >
        </div>
    </div>
    <div class="coins_emitted">
        <div class="title">Coins Emitted</div>
        <div class="value">{{ totalCoins | moneyParse | bitNumber }}</div>
    </div>
    <div class="transactions">
        <div class="title">Transactions</div>
        <div class="value">{{ txCount + height | bitNumber }}</div>
    </div>
    <div class="hash_rate">
        <div class="title">Hash Rate (aprox):</div>
        <div class="value">{{
            NetworkHashrate | hashPowerConverter: 'speed'
        }}</div>
    </div>
</div>
