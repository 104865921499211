<div class="top_box" *ngIf="navIsOpen === false">
    <div class="left_box">
        <a
            [routerLink]="['/']"
            class="back_btn"
            [ngClass]="{ hide_back_btn: searchIsOpen === true }"
            >Back</a
        >
    </div>
    <app-search (isVisible)="onIsVisible($event)"></app-search>
</div>
<div *ngIf="BlockNotFound === false; else elseBlock">
    <div class="block_details">
        <div class="heading_block">
            <h2 class="currency_name">Nirmata block</h2>
            <div class="height_block">
                <a
                    *ngIf="prevBlockId"
                    class="prev_block"
                    [routerLink]="['/block', prevBlockId]"
                    routerLinkActive="active">
                    <svg x="0px" y="0px" viewBox="0 0 477.175 477.175">
                        <g>
                            <path
                                d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
              c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" />
                        </g>
                    </svg>
                </a>
                {{ Block.height }}
                <a
                    *ngIf="nextBlockId"
                    class="next_block"
                    [routerLink]="['/block', nextBlockId]"
                    routerLinkActive="active">
                    <svg x="0px" y="0px" viewBox="0 0 477.175 477.175">
                        <g>
                            <path
                                d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
              c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
              " />
                        </g>
                    </svg>
                </a>
            </div>
            <span class="hash_block">{{ Block.id }}</span>
        </div>
        <div class="wrapper_table_block_details">
            <table class="left_table">
                <tr class="block_type">
                    <td>Type:</td>
                    <td>
                        <span *ngIf="Block.type === 0" class="pos">PoS</span>
                        <span *ngIf="Block.type === 1" class="pow">PoW</span>
                    </td>
                </tr>
                <tr>
                    <td>Timestamp (UTC):</td>
                    <td>
                        <span *ngIf="Block.timestamp > 0">{{
                            Block.timestamp * 1000 | date: 'YYYY-MM-dd HH:mm:ss':'+0000'
                        }}</span>
                        <span *ngIf="Block.timestamp === 0">0</span>
                    </td>
                </tr>
                <tr>
                    <td>Actual Timestamp (UTC):</td>
                    <td>
                        <span *ngIf="Block.actual_timestamp > 0">{{
                            Block.actual_timestamp * 1000 | date: 'YYYY-MM-dd HH:mm:ss':'+0000'
                        }}</span>
                        <span *ngIf="Block.actual_timestamp === 0">0</span>
                    </td>
                </tr>
                <tr>
                    <td>Difficulty:</td>
                    <td>{{ Block.difficulty | bitNumber }}</td>
                </tr>
                <tr>
                    <td>Miner text info:</td>
                    <td *ngIf="minerTextInfo; else noneMinerTextInfo">
                        {{ minerTextInfo }}
                    </td>
                    <ng-template #noneMinerTextInfo><td>-</td></ng-template>
                </tr>
                <tr class="cumulative_diff_adjusted">
                    <td>Cumulative diff adjusted:</td>
                    <td>{{ Block.cumulative_diff_adjusted | bitNumber }}</td>
                </tr>
                <tr class="cumulative_diff_presize">
                    <td>Cumulative diff presize:</td>
                    <td>{{ Block.cumulative_diff_precise | bitNumber }}</td>
                </tr>
                <tr>
                    <td>Orphan:</td>
                    <td>{{ Block.is_orphan ? 'yes' : 'no' }}</td>
                </tr>
                <tr>
                    <td>Base reward:</td>
                    <td>{{ Block.base_reward | moneyParse }}</td>
                </tr>
                <tr class="transactions_fee">
                    <td>Transactions fee:</td>
                    <td>{{ Block.total_fee | moneyParse }}</td>
                </tr>
            </table>

            <table class="right_table">
                <tr>
                    <td>Reward penalty:</td>
                    <td>{{ Block.penalty | moneyParse }}</td>
                </tr>
                <tr>
                    <td>Reward:</td>
                    <td>{{ Block.summary_reward | moneyParse }}</td>
                </tr>
                <tr>
                    <td>Total block size, bytes:</td>
                    <td>{{ Block.block_cumulative_size }}</td>
                </tr>
                <tr class="effective_txs_median">
                    <td>Effective txs median, bytes:</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>This block fee median</td>
                    <td>{{ ThisBlockFeeMedian | moneyParse }}</td>
                </tr>
                <tr>
                    <td>Effective fee median</td>
                    <td>{{ EffectiveFeeMedian | moneyParse }}</td>
                </tr>
                <tr class="current_txs_median">
                    <td>Current txs median, bytes:</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Transactions:</td>
                    <td>{{ Block.transactions_details?.length }}</td>
                </tr>
                <tr class="total_transactions_size">
                    <td>Total transactions size, bytes:</td>
                    <td>{{ Block.total_txs_size }}</td>
                </tr>
                <tr class="tr_seed">
                    <td>Seed</td>
                    <td>{{ Block?.pow_seed }}</td>
                </tr>
            </table>
        </div>
    </div>

    <div class="block_transactions_list">
        <div class="title">Transactions</div>
        <table class="table_block_transaction">
            <thead>
                <tr>
                    <th class="hash">Hash</th>
                    <th class="fee">Fee</th>
                    <th class="total_amount">Total amount</th>
                    <th class="size">Size</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tr of Block.transactions_details">
                    <td class="hash">
                        <a [routerLink]="['/transaction', tr.id]">{{
                            tr.id
                        }}</a>
                    </td>
                    <td class="fee">{{ tr.fee | moneyParse }}</td>
                    <td class="total_amount">{{ tr.amount | moneyParse }}</td>
                    <td class="size">{{ tr.blob_size }} bytes</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #elseBlock>
    <div class="not-found">Block not found</div>
</ng-template>
