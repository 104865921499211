<input
    type="text"
    [ngClass]="{ show: isVisibleInput === true }"
    [(ngModel)]="setSearch"
    (keydown)="onKeydown($event)"
    (keypress)="onKeydown($event)"
    (keyup)="onKeydown($event)"
    placeholder="block height / block hash / transaction hash" />
<button class="run_search" (click)="searchFunc()"></button>
<span class="search_not_found" *ngIf="searchNotFound === true">
    No matching records found!
</span>
